/* eslint-disable */
import getUA from './getUA'
import base64 from 'base-64'
import utf8 from 'utf8'
import parseQueryString from './parseQueryString'
import { isIOS } from 'vant/es/utils'
import CryptoJS from 'crypto-js'
import moment from 'moment'
import { toRaw } from 'vue'
import { getRandomInt } from './utils'

/**
 * 把回调函数放入 window[sdkSymbol] 对象中
 * 避免跟 window 的属性名发生冲突
 */
const sdkSymbol = Symbol.for('sdk')
const envDataSymbol = Symbol.for('envData')
window[sdkSymbol] = window[sdkSymbol] || {}

const mockEnvData = {
  channel: '',
  device: {
    deviceId: '0da32a25ac0df478',
    model: 'OnePlus LE2100'
  },
  group: {
    // groupId: '7563a7d4-98f0-45a2-9c34-f5f95593b317',
    groupId: 'c1df0d1d-8fde-40ba-9744-715a0774adbe',
    // inviteID: '372151638',
    // groupName: '170团队会员0元7天试用',
    // groupId: '41524085-4f6e-4c57-ae98-e75f42a9e098',
    inviteID: '816623097',
    groupName: '名额不够Android',
    groupColor: '#2479ed',
    avatar: '',
    groupUserCount: 301,
    vipInfo: {
      is_vip: false,
      cmsTotalSite: 0,
      cmsExpireTime: 0
    },
    industryCode: '020010',
    photoAggType: 0,
    industryName: '房屋建筑业',
    customerTotal: 0,
    canUseFreeCMS: false,
    isCMSPeriod: false,
    isCMSAdd: false,
    userRoleInCurrentTeam: 0 // 当前账号在当前团队内的角色
  },
  groupVipConfig: {
    daysLimit: 30
  },
  sourceDetail: 'groupGroupSetting',
  user: {
    headimgurl:
      'https://net-cloud.xhey.top/group/headimg/60224057-e5f1-4dac-8a19-1214a5868e29.jpeg',
    isTry: false,
    mobile: '15550031338',
    nickname: '中关村最帅',
    userId: 'xuser-1c4e95e9-9079-4ef2-aebe-54d8432c1092',
    // userId: 'xuser-0fbcd0ce-4721-435a-8745-896f76a26b1d',
    vipInfo: {
      adFreeVipExpireTime: 1709206179,
      adFreeVipType: 0,
      adFreeVipTypeDesc: '一个月免广告会员',
      isAdFreeVip: false,
      isVip: true,
      removeWatermarkCounts: 14674,
      skinPhoto: '',
      vipDesc: '一年vip会员',
      vipExpireTime: 1912677855,
      vipKind: 0,
      vipType: '1',
      msg: '',
      status: 0
    }
  }
}

/**
 * 调用 JsBridge 与客户端 native 交互
 * @param {*} type
 * @param {*} subtype
 * @param {*} content
 */
const invokeJsBridge = ({ type, subtype, content }) => {
  const message = { type, subtype, content }
  // 如果在华为环境
  if (getUA().isHarmony) {
    console.log('isHarmony')
    // 校验下是否包含华为 jsb
    if (window?.harmony && typeof window?.harmony?.callHarmony == 'function') {
      console.log('hasHarmony JSB')
      window?.harmony?.callHarmony(JSON.stringify(message))
    } else {
      // 使用 Android 兜底
      console.log('Harmony fallback to Android  JSB')
      window.android?.callAndroid(JSON.stringify(message))
    }
  } else if (getUA().isAndroid) {
    console.log('isAndroid')
    window.android?.callAndroid(JSON.stringify(message))
  } else if (getUA().isIOS) {
    console.log('isIOS')
    window.webkit?.messageHandlers.XCamera.postMessage(message)
  } else {
    // TODO:非 APP 环境不可用
    console.log('not in App ')
  }
}

/**
 * 调用 JsBridge 与客户端 native 交互新版。 客户端通过识别schema协议来进行，2024/195版本，暂未启用，方案：https://alidocs.dingtalk.com/i/nodes/93NwLYZXWygxQ5pQtllGKvbqJkyEqBQm
 * @param {*} schema   bridge协议tmc://v1/common/SHARE_MP
 * @param params bridge参数
 * @param callback 回调函数
 */
const invokeJsBridgeNew = (schema, params = {}, callback = '') => {
  return new Promise((resolve, reject) => {
    try {
      if (isInApp) {
        callback &&
          (window[callback] = () => {
            resolve()
          })
        const message = { schema, params, callback }
        // 如果在华为环境
        if (getUA().isHarmony) {
          console.log('isHarmony')
          if (
            window?.harmony &&
            typeof window?.harmony?.callHarmony == 'function'
          ) {
            window?.harmony?.callHarmony(JSON.stringify(message))
          } else {
            // 使用 Android 兜底
            window.android?.callAndroid(JSON.stringify(message))
          }
        } else if (getUA().isAndroid) {
          window.android?.callAndroid(JSON.stringify(message))
        } else if (getUA().isIOS) {
          window.webkit?.messageHandlers.XCamera.postMessage(message)
        } else {
          console.log('其他系统')
        }
      } else {
        console.log('not in App ')
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const isInApp = !!(
  (getUA().isIOS &&
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.XCamera) ||
  (getUA().isAndroid && getUA().isTodayCamera) ||
  (getUA().isHarmony && getUA().isTodayCamera)
)
/**
 * TODO 初始化时被调用 3 次
 * @returns
 */
export const fetchEnvAttrs = () => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackFuncName = `XCameraGetEnvAttrs_${Date.now()}_${Math.floor(
        Math.random() * 10000
      )}`

      window[callbackFuncName] = function (dataString) {
        const envData = JSON.parse(dataString)
        resolve(envData)
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_GET_ENV_ATTRS',
        content: {
          callback: callbackFuncName
        }
      }
      invokeJsBridge(message)
    } else {
      // mockEnvData.group.groupId = getGroupIdByAbParam()
      mockEnvData.group.groupId = 'c1df0d1d-8fde-40ba-9744-715a0774adbe'
      mockEnvData.group.groupID = 'c1df0d1d-8fde-40ba-9744-715a0774adbe'
      resolve(mockEnvData)
    }
  })
}

export const getGroupIdByAbParam = () => {
  const query = parseQueryString()
  const ab = Number(query.dev_test_ab) || 0
  // Mock 使用的 GroupID 列表
  const groups = {
    //实验分组0  GroupName 压测天团-万人团 UserCount 8989
    0: { groupID: 'bb3fea1e-2b92-4509-8eb7-1f0260752412' },
    //实验分组1 GroupName 照片大于2千（有会员） UserCount 122
    1: { groupID: 'face39df-dce3-458d-8e34-b481da6b60b6' },
    //实验分组2  GroupName 照片大于2千（无会员） UserCount 40
    2: { groupID: 'c2289af6-ff02-4f3a-bd5f-c04fd897d673' },
    //实验分组3  GroupName 美团外卖（y）超过5相同 UserCount 18
    3: { groupID: '00f994bb-8d34-4be7-b9d8-99c2c2685538' },
    //实验分组4  GroupName IOS拼图 UserCount 11
    4: { groupID: '1dc4b1a5-832e-4e0c-bd5b-a19db5d7bc0b' },
    //实验分组0  GroupName 个人主页（超过5人不同） UserCount 22
    5: { groupID: 'e3d45dc3-a343-46c9-af30-317452e6ddf4' }
  }
  return groups[ab]?.groupID
}

export const saveImage = (dataURL) => {
  const message = {
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'H5_SAVE_IMAGE',
    content: {
      image: dataURL,
      callback: ''
    }
  }
  if (getUA().isAndroid) {
    window.android.callAndroid(JSON.stringify(message))
  } else {
    window.webkit.messageHandlers.XCamera.postMessage(message)
  }
}

/**
 * aiLogo 新的按钮，保存并使用
 *
 * @params { imageBase64: string, url: string }
 */
export const saveAndUseImage = (params) => {
  const message = {
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'H5_USE_AI_LOGO',
    content: {
      ...params
    }
  }
  if (getUA().isAndroid) {
    window.android.callAndroid(JSON.stringify(message))
  } else {
    window.webkit.messageHandlers.XCamera.postMessage(message)
  }
}

export const setClipboard = (text) => {
  return new Promise((resolve) => {
    if (isInApp) {
      window['XCameraSetClipboardSuccess'] = function () {
        resolve()
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_SET_CLIPBOARD',
        content: {
          text,
          callback: 'XCameraSetClipboardSuccess'
        }
      }
      invokeJsBridge(message)
    } else {
      navigator.clipboard.writeText(text)
    }
  })
}

export const encodeParams = (params, callback) => {
  // const message = {
  //   type: 'JS_CALL_NATIVE_FUNC',
  //   subtype: 'H5_ENCODE_PARAMS',
  //   content: {
  //     params,
  //     callback: 'getXCameraEncodeParams'
  //   }
  // }

  const message = {
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'H5_ENCODE_PARAMS',
    content: {
      params,
      callback
    }
  }
  invokeJsBridge(message)
}

export const decodeParams = (data, callback) => {
  const message = {
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'H5_DECODE_DATA',
    content: {
      data,
      callback
    }
  }
  invokeJsBridge(message)
}

export const share = (data) => {
  return new Promise((resolve) => {
    if (isInApp) {
      window['XCameraShareTarget'] = () => {
        resolve()
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_SHARE',
        content: {
          ...data,
          callback: 'XCameraShareTarget'
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve()
    }
  })
}

export const shareMp = (data) => {
  return new Promise((resolve) => {
    if (isInApp) {
      window['XCameraShareMpTarget'] = () => {
        resolve()
      }

      let message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'SHARE_MP',
        content: {
          ...data,
          callback: 'XCameraShareMpTarget'
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        message = {
          callback: 'XCameraShareMpTarget',
          schema: 'tmc://v1/common/SHARE_MP',
          params: data
        }
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve()
    }
  })
}

export const launchMP = (data) => {
  const message = {
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'H5_LAUNCH_MP',
    content: {
      ...data,
      callback: ''
    }
  }
  if (getUA().isAndroid) {
    window.android.callAndroid(JSON.stringify(message))
  } else {
    window.webkit.messageHandlers.XCamera.postMessage(message)
  }
}

export const forceUpdate = () => {
  const message = {
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'H5_FORCE_UPDATE_APP',
    content: {
      callback: ''
    }
  }
  if (getUA().isAndroid) {
    window.android.callAndroid(JSON.stringify(message))
  } else {
    window.webkit.messageHandlers.XCamera.postMessage(message)
  }
}

export const logout = () => {
  const message = {
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'H5_LOGOUT',
    content: {
      callback: 'XCameraLogoutSuccess'
    }
  }
  if (getUA().isAndroid) {
    window.android.callAndroid(JSON.stringify(message))
  } else {
    window.webkit.messageHandlers.XCamera.postMessage(message)
  }
}

/**
 * 调登录引导页
 */
export const login = () => {
  const message = {
    type: 'TOVIEW_LOCAL_PAGE',
    subtype: 'login_guide_page',
    content: {
      button: 1
    }
  }
  invokeJsBridge(message)
}

export const closeWebView = (options = {}) => {
  const message = {
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'H5_CLOSE_WEBVIEW',
    content: {
      ...options,
      callback: ''
    }
  }
  invokeJsBridge(message)
}

// 关闭弹窗
export const closeModal = () => {
  const message = {
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'H5_CLOSE_MODAL',
    content: {
      callback: ''
    }
  }
  console.log('closeModal:', message)
  if (getUA().isAndroid) {
    window.android.callAndroid(JSON.stringify(message))
  } else {
    window.webkit.messageHandlers.XCamera.postMessage(message)
  }
}

export const toView = ({
  subtype = '',
  content = {},
  type = 'TOVIEW_LOCAL_PAGE'
}) => {
  const message = {
    type,
    subtype,
    content
  }

  invokeJsBridge(message)
}

export const toOutView = ({ name, url }) => {
  const message = {
    type: 'TOVIEW_H5_OUT_APP',
    subtype: '',
    content: {
      name,
      url
    }
  }
  if (getUA().isAndroid) {
    window.android.callAndroid(JSON.stringify(message))
  } else {
    window.webkit.messageHandlers.XCamera.postMessage(message)
  }
}

export const toInView = ({
  name,
  url,
  subtype = '',
  groupId = '',
  fromPage = ''
}) => {
  if (isInApp) {
    const message = {
      type: 'TOVIEW_H5_IN_APP',
      subtype,
      content: {
        name,
        url,
        groupId,
        fromPage
      }
    }
    invokeJsBridge(message)
  } else {
    window.location.href = url
  }
}

export const fetchStatusCode = () => {
  return new Promise((resolve) => {
    if (isInApp) {
      window['getXCameraStatusCode'] = function (dataString) {
        const envData = JSON.parse(dataString)
        resolve(envData)
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_GET_APP_STATUS_CODE',
        content: {
          callback: 'getXCameraStatusCode'
        }
      }
      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve('')
    }
  })
}

export const appVersion = () => {
  //iOS:TodayCamera/2.9.104 Android:TodayCamera/v2.9.104
  //Android:TodayCamera/v2.8.235.0  || v2.8.235.0(D)
  const appVersionUAItem = navigator.userAgent
    .split(' ')
    .find((uaItem) => uaItem.includes('TodayCamera'))
  if (appVersionUAItem) {
    return appVersionUAItem
      .replace('TodayCamera/', '')
      .replace('TodayCameraPro/', '')
      .replace('v', '')
      .replace('(D)', '')
  }
  return ''
}

// >= 3.0.35
export const appVersionCode = () => {
  const appVersionUAItem = navigator.userAgent
    .split(' ')
    .find((uaItem) => uaItem.includes('AppVersionCode'))
  if (appVersionUAItem) {
    return appVersionUAItem.replace('AppVersionCode/', '')
  }
  return '30016000'
}

export const encodeHttpParams = (params) => {
  return new Promise((resolve) => {
    const callback = `getXCameraEncodeParams_${Date.now()}_${Math.floor(
      Math.random() * 1000
    )}`

    window[callback] = function (encodeString) {
      resolve({
        params: encodeString
      })
    }
    encodeParams(params, callback)
  })
}

export const baseDecode = (res) => {
  const bytes = base64.decode(res)
  const text = utf8.decode(bytes)
  return text
}

export const baseEncode = (text) => {
  const bytes = utf8.encode(text)
  return base64.encode(bytes)
}

export const decodeHttpResult = (result, isBase64 = false) => {
  const callback = `getXCameraEncodeParams_${Date.now()}_${Math.floor(
    Math.random() * 1000
  )}`

  return new Promise((resolve, reject) => {
    window[callback] = function (decodeString) {
      try {
        if (isBase64) {
          decodeString = baseDecode(decodeString)
        }

        const result = JSON.parse(decodeString)
        resolve(result)
      } catch (e) {
        console.log('parse error:', e)
        reject(e)
      }
    }
    decodeParams(result.result, callback)
  })
}

/**
 * 跳转支付
 * @param {*} param0
 * @returns
 */
export const toPayPersonalVip = ({
  goodId, // VIP 套餐 ID
  payway, // 支付方式
  couponID = '', // 优惠券ID
  type = '', // VIP 商品类型
  needLogin = false, // 是否需要登录
  harmonyGoodsType // 鸿蒙商品类型
}) => {
  return new Promise((resolve) => {
    if (isInApp) {
      let callbackname = 'XCameraToPayPersonalVip'
      callbackname += `${Date.now()}_${Math.floor(Math.random() * 1000)}`
      window[callbackname] = (paySuccess) => {
        console.log('JSB Callback')
        console.log(paySuccess)
        resolve(paySuccess)
      }
      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'personal_vip_payment',
        content: {
          // 支付宝支付: Alipay; 微信支付: WeChatPay; 苹果支付: ApplePay
          payway,
          goodId,
          couponID,
          needLogin, // 0122 仅安卓， iOS默认为允许游客购买
          needBindPhoneNumber: !needLogin, // 0122 仅安卓， iOS默认为允许游客购买，不能阻塞
          callback: callbackname
        }
      }

      if (getUA().isHarmony) {
        message.content.harmonyGoodsType = harmonyGoodsType
      }

      const query = parseQueryString()

      if (query.sourceDetail) {
        message.content.sourceDetail =
          query.sourceDetail === 'personalNoAd'
            ? 'personalNoAdFront'
            : query.sourceDetail
      } else if (query.marketId === '876' || query.marketId === '851') {
        // 兼容老版本，085免广告会员，客户端在启屏页写死的url,090以前老版本url中没有sourceDetail
        message.content.sourceDetail = 'personalNoAdFront'
      }
      // 华为调起支付的时候需要额外传入商品类型和商品 ID
      if (getUA().isHarmony) {
        message.content.type = String(type) // VIP Type是 0 的话，type字段被移除了
      }
      console.log('JSB call message')
      console.log(message)
      invokeJsBridge(message)
    } else {
      resolve('false')
    }
  })
}

// 团队会员支付
export const toPayGroupVip = ({
  groupId,
  goodId,
  payway,
  couponID = '',
  taskID,
  isCustomerManage,
  showRenewSucDialog = true,
  price
}) => {
  return new Promise((resolve) => {
    if (isInApp) {
      let callbackname = 'XCameraToPayGroupVip'
      callbackname += `${Date.now()}_${Math.floor(Math.random() * 1000)}`
      window[callbackname] = (res) => {
        const [paySuccess, statusCode] = res.split(',')

        resolve({ paySuccess, statusCode })
      }
      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: isCustomerManage
          ? 'customer_manage_vip_payment'
          : 'group_vip_payment',
        content: {
          groupId,
          // 支付宝支付: Alipay; 微信支付: WeChatPay; 苹果支付: ApplePay
          payway,
          goodId,
          couponID,
          taskID,
          price, // 160安卓升级svip新增，按传入价格支付
          showRenewSucDialog, // 客户端是否展示续费成功弹窗
          callback: callbackname
        }
      }

      const query = parseQueryString()

      if (query.sourceDetail) {
        message.content.sourceDetail = query.sourceDetail || query.source_detail
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve({
        paySuccess: 'false'
      })
    }
  })
}

// ai生成logo支付
export const toPayAILogo = ({ logoUrl, payway, aiLogoTaskId, goodsId }) => {
  return new Promise((resolve) => {
    if (isInApp) {
      let callbackname = 'XCameraBuyLogoCallback'
      callbackname += `${Date.now()}_${Math.floor(Math.random() * 1000)}`

      window[callbackname] = (res) => {
        resolve(res.status === 0)
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_BUY_LOGO',
        content: {
          aiLogoTaskId,
          logoUrl,
          payway,
          goodsId,
          callback: callbackname
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve('false')
    }
  })
}

/**
 * ai logo拼图
 * ai 生成logo之后，把logo图片传给客户端生成logo和品牌文字的拼图
 *
 * key: 关键词-品牌文字
 * logoURL: ai生成的logo url
 */
export const generateAILogo = ({ keyword, logoUrl }) => {
  return new Promise((resolve) => {
    if (isInApp) {
      let callbackname = 'XCameraGenerateLogoCallback'
      callbackname += `${Date.now()}_${Math.floor(Math.random() * 1000)}`

      window[callbackname] = (res) => {
        resolve(JSON.parse(res))
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_GENERATE_LOGO',
        content: {
          keyword,
          logoUrl,
          callback: callbackname
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve([
        'https://net-cloud.xhey.top/6628b928-7237-11ee-9ed0-00163e378a1d.png',
        'https://net-cloud.xhey.top/6628b928-7237-11ee-9ed0-00163e378a1d.png'
      ])
    }
  })
}

// 购买水印次数支付
export const toPayWatermarkCount = ({ goodId, payway, harmonyGoodsType }) => {
  return new Promise((resolve) => {
    if (isInApp) {
      let callbackname = 'XCameraToPayWatermarkCount'
      window[callbackname] = (paySuccess) => {
        resolve(paySuccess)
      }
      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'watermark_count_payment',
        content: {
          // 支付宝支付: Alipay; 微信支付: WeChatPay; 苹果支付: ApplePay
          payway,
          goodId,
          harmonyGoodsType,
          callback: callbackname
        }
      }
      console.log('支付toView:', message)
      invokeJsBridge(message)
      // if (getUA().isAndroid) {
      //   window.android.callAndroid(JSON.stringify(message))
      // } else {
      //   window.webkit.messageHandlers.XCamera.postMessage(message)
      // }
    } else {
      resolve('false')
    }
  })
}

// 购买验真VIP会员
export const toPayVerifyVip = ({ payway, goodId, sourceDetail }) => {
  return new Promise((resolve) => {
    if (isInApp) {
      let callbackname = 'XCameraToPayVerifyVip'
      window[callbackname] = (paySuccess) => {
        resolve(paySuccess)
      }
      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'purchase_photo_verification',
        content: {
          payway,
          goodId,
          sourceDetail,
          callback: callbackname
        }
      }
      console.log('支付toView:', message)
      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve('false')
    }
  })
}

// 点击恢复购买
export const toResumePurchase = (
  subtype = 'personal_vip_restore_purchase',
  content = {}
) => {
  return new Promise((resolve) => {
    if (isInApp) {
      let callbackname =
        subtype === 'personal_vip_restore_purchase'
          ? 'XCameraPersonalVipRestore'
          : 'XCameraGroupVipRestore'
      callbackname += `${Date.now()}_${Math.floor(Math.random() * 1000)}`
      window[callbackname] = (paySuccess) => {
        resolve(paySuccess)
      }
      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype,
        content: {
          ...content,
          callback: callbackname
        }
      }
      console.log(message)
      if (getUA().isIOS) {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(false)
    }
  })
}

// 用户点击客户端系统返回键或者webview左上角返回键，调用
export const xcameraBackClick = (callback) => {
  window['XCameraBackClick'] = function () {
    callback()
  }
}

// 仅用于照片台账表页，会员状态刷新时调用H5注册的钩子函数
export const registerOnPageResume = (callback) => {
  window['XCameraBackToWebview'] = function () {
    callback()
  }
}

// 原生界面回到webview页面时调用的H5注册的钩子函数，全局通用（除了半屏的webView）
export const registerOnNativePageBackToWebview = (callback) => {
  window['NativePageBackToWebview'] = function () {
    callback && callback()
  }
}

/**
 * 获取图片
 * @param {string} image_key 用于获取图片的唯一标识
 * @param {object} options 扩展字段
 */
export const fetchImage = ({ image_key, options }) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackname = 'XCameraFetchImageSuccess'

      window[callbackname] = (data) => {
        if (data) {
          resolve('data:image/png;base64,' + data)
        } else {
          resolve('')
        }
      }

      invokeJsBridge({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_LOAD_IMAGE',
        content: {
          image_key,
          options,
          callback: callbackname
        }
      })
    } else {
      resolve('')
    }
  })
}

/**
 * 获取水印数据
 * @param {*} watermark_id  水印id
 * @param {*} options  扩展字段
 */
export const fetchWatermarkContent = ({ watermark_id, options }) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackname = 'XCameraFetchWatermarkContentSuccess'

      window[callbackname] = (data) => {
        let str = data

        if (options.isBase64) {
          str = baseDecode(data)
        }

        resolve(JSON.parse(str))
      }

      invokeJsBridge({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_WATER_MARK_CONTENT',
        content: {
          watermark_id,
          options,
          callback: callbackname
        }
      })
    } else {
      resolve({
        id: '10',
        base_id: '10',
        watermarkType: 1,
        theme: {
          alpha: '1',
          color: '#FFC233',
          id: 110,
          widthScale: '1.0',
          switchStatus: true,
          sizeScale: '1.0',
          textColor: '#FFFFFF',
          fontScale: '1.0'
        },
        version: '1',
        ugcType: 0,
        logo: {
          alpha: '1.000',
          scale: '0.794',
          gravity: 0,
          id: 14,
          switchStatus: true,
          originalUrl:
            'https://net-cloud.xhey.top/group/logo/ce14ec21-59f4-491b-909b-a75f6ee3dc4d.jpg',
          url: 'https://net-cloud.xhey.top/group/logo/865afa06c3ecbecbdca0ffe716abf891.png'
        },
        createTime: '1615361372633',
        items: [
          {
            style: 2400,
            content:
              'https://net-cloud.xhey.top/data/845b1d98-78e3-4451-8a58-feea575d226d.png',
            id: 240,
            editType: 0,
            title: '员工拍照规范',
            switchStatus: false,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 0,
            content: '2321-#YYDSHRB97977你明明记名字名字嘻嘻明敏咯',
            id: 11,
            editType: 2,
            title: '自定义文字',
            switchStatus: true,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 4100,
            content: '自动生成唯一编号',
            id: 410,
            editType: 1,
            title: '编号',
            switchStatus: false,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 4000,
            content: '1',
            id: 400,
            editType: 2,
            title: '计数',
            switchStatus: false,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 100,
            content: '日期+时刻',
            id: 1,
            editType: 0,
            title: '时间',
            switchStatus: true,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 200,
            content: '天气+温度',
            id: 4,
            editType: 0,
            title: '天气',
            switchStatus: false,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 300,
            content: '城市+地点',
            id: 2,
            editType: 4,
            title: '地点',
            switchStatus: true,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 400,
            content: '高度',
            id: 5,
            editType: 0,
            title: '海拔',
            switchStatus: false,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 600,
            content: '方向+角度',
            id: 6,
            editType: 0,
            title: '方位角',
            switchStatus: true,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 700,
            content: '公里/小时',
            id: 7,
            editType: 0,
            title: '速度',
            switchStatus: false,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 500,
            content: '度',
            id: 3,
            editType: 0,
            title: '经纬度',
            switchStatus: true,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 2100,
            content: '地点缩略图',
            id: 210,
            editType: 0,
            title: '地图',
            switchStatus: false,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 2200,
            content: '已添加',
            id: 220,
            editType: 0,
            title: '二维码（扫码导航）',
            switchStatus: false,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 800,
            content: '分贝（等级）',
            id: 8,
            editType: 0,
            title: '分贝',
            switchStatus: false,
            userCustom: false,
            itemShowStyle: 0
          },
          {
            style: 0,
            content: '请输入内容...',
            id: 13,
            editType: 3,
            title: '拍摄人',
            switchStatus: true,
            userCustom: true,
            itemShowStyle: 0
          },
          {
            style: 0,
            content: '名额',
            id: 1650884417,
            editType: 3,
            title: '测试',
            switchStatus: true,
            userCustom: true,
            itemShowStyle: 0
          }
        ],
        imageItems: [
          {
            alpha: '1.000',
            scale: '0.794',
            gravity: 0,
            id: 14,
            switchStatus: true,
            originalUrl:
              'https://net-cloud.xhey.top/group/logo/ce14ec21-59f4-491b-909b-a75f6ee3dc4d.jpg',
            url: 'https://net-cloud.xhey.top/group/logo/865afa06c3ecbecbdca0ffe716abf891.png'
          }
        ],
        vipType: 0,
        name: '自定义水印',
        update_time: '1615361372633'
      })
    }
  })
}

/**
 * 获取拼图汇报模板数据
 * @param {*} param0
 * @returns
 */
export const fetchtemplateContent = ({ options }) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackname = 'XCameraFetchWorkTemplateContentSuccess'

      window[callbackname] = (data) => {
        let str = data

        if (options.isBase64) {
          str = baseDecode(data)
        }

        resolve(JSON.parse(str))
      }

      invokeJsBridge({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_WORK_TEMPLATE_CONTENT',
        content: {
          options,
          callback: callbackname
        }
      })
    } else {
      resolve({
        id: '',
        name: '工作记录哈哈',
        extraInfo: {
          meta: 'ImageAndText'
        },
        header: {
          id: 'c005',
          base_id: 'c005',
          version: '2',
          theme: {
            color: '#348D71',
            widthScale: '1.0',
            brandColorList: ['#348D71']
          },
          imageItems: [],
          logo: {
            id: 120,
            alpha: '1.000',
            gravity: 0,
            switchStatus: true,
            url: 'https://net-cloud.xhey.top/group/logo/6e5e9c39-9288-49b2-ab36-5c0920dca841.png',
            size: 46,
            scale: '0.230'
          },
          items: [
            {
              style: 0,
              editType: 2,
              id: 13,
              content: '工作记录哈哈',
              title: '大标题',
              switchStatus: true,
              userCustom: false
            }
          ]
        }
      })
    }
  })
}

export const fetchWatermarkItems = () => {
  return new Promise((resolve) => {
    if (isInApp) {
      window['XCameraGetWatermarkItems'] = function (dataString) {
        const envData = JSON.parse(dataString)
        resolve(envData)
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_GET_WATERMARK_ITEMS',
        content: {
          callback: 'XCameraGetWatermarkItems'
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(
        JSON.parse(
          '{"latLnt":"116.19499322696977,40.04894641004058","address":"北京市海淀区中关村创客小镇"}'
        )
      )
    }
  })
}

export const reportLocationData = (data) => {
  const message = {
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'REPORT_LOCATION_DATA',
    content: {
      ...data,
      callback: ''
    }
  }
  console.log(message)
  console.log(756, getUA().isAndroid)
  if (getUA().isAndroid) {
    window.android.callAndroid(JSON.stringify(message))
  } else {
    window.webkit.messageHandlers.XCamera.postMessage(message)
  }
}

// 调用客户端水印筛选
export const watermarkNativeFilter = (data) => {
  return new Promise((resolve) => {
    if (!isInApp) {
      resolve({
        groupId: 'face39df-dce3-458d-8e34-b481da6b60b6',
        selectedWatermarks: []
      })
    }

    const callbackname = 'XCameraSelectWatermarkFinish'

    window[callbackname] = (data) => {
      if (data) {
        try {
          console.log('格式化数据：', JSON.parse(data))
          resolve(JSON.parse(data))
        } catch (e) {
          console.error(e)
        }
      } else {
        resolve(null)
      }
    }

    const message = {
      type: 'JS_CALL_NATIVE_FUNC',
      subtype: 'to_select_watermark_page',
      content: {
        ...data,
        callback: callbackname
      }
    }

    console.log('to_select_watermark_page', message)

    if (getUA().isAndroid) {
      window.android.callAndroid(JSON.stringify(message))
    } else {
      window.webkit.messageHandlers.XCamera.postMessage(message)
    }
  })
}

// 调用客户端成员筛选
export const memberNativeFilter = (data) => {
  return new Promise((resolve) => {
    if (!isInApp) {
      resolve({
        groupId: 'face39df-dce3-458d-8e34-b481da6b60b6',
        userIDList: [],
        departmentIDList: [],
        departmentUserNum: 0
      })
    }
    const callbackname = 'XCameraSelectMemberFinish'

    window[callbackname] = (data) => {
      if (data) {
        try {
          console.log('格式化数据：', JSON.parse(data))
          resolve(JSON.parse(data))
        } catch (e) {
          console.error(e)
        }
      } else {
        resolve(null)
      }
    }

    const message = {
      type: 'JS_CALL_NATIVE_FUNC',
      subtype: 'to_select_team_members_page',
      content: {
        ...data,
        callback: callbackname
      }
    }

    console.log('to_select_team_members_page', message)

    if (getUA().isAndroid) {
      window.android.callAndroid(JSON.stringify(message))
    } else {
      window.webkit.messageHandlers.XCamera.postMessage(message)
    }
  })
}

export const adjustCustomerManageMembers = ({ groupId, sourceDetail }) => {
  return new Promise((resolve) => {
    if (isInApp) {
      let callbackname = 'XCameraAdjustCustomerManageMembers'
      window[callbackname] = (paySuccess) => {
        resolve(paySuccess)
      }
      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'adjust_customer_manage_members',
        content: {
          groupId,
          sourceDetail
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve()
    }
  })
}

// 客户标签选择
export const customerTagsNativeFilter = (data) => {
  return new Promise((resolve) => {
    if (!isInApp) {
      resolve([])
    }

    const callbackname = 'XCameraSelectCustomerTagsFinish'

    window[callbackname] = (data) => {
      console.log('=========标签筛选返回：', data)

      if (data) {
        try {
          resolve(JSON.parse(data))
        } catch (e) {
          console.error(e)
        }
      } else {
        resolve(null)
      }
    }

    const message = {
      type: 'JS_CALL_NATIVE_FUNC',
      subtype: 'to_select_customer_tags_page',
      content: {
        ...data,
        callback: callbackname
      }
    }

    if (getUA().isAndroid) {
      window.android.callAndroid(JSON.stringify(message))
    } else {
      // FIXME:
      console.log('==========标签筛选参数', message)

      window.webkit.messageHandlers.XCamera.postMessage(message)
    }
  })
}

// 跳转联系客服,这个方法客户端可以自动上报埋点
export const contactUsOnline = ({ onlineFrom = '' }) => {
  const message = {
    type: 'TOVIEW_LOCAL_PAGE',
    subtype: 'to_contact_us_online',
    content: {
      onlineFrom // 在线客服来源
    }
  }
  if (getUA().isAndroid) {
    window.android.callAndroid(JSON.stringify(message))
  } else {
    window.webkit.messageHandlers.XCamera.postMessage(message)
  }
}

// 跳转登录页
export const toNativeLoginPage = ({
  jumpType = '0',
  backType = '0',
  fromPage = ''
} = {}) => {
  const message = {
    type: 'TOVIEW_LOCAL_PAGE',
    subtype: 'login_page',
    content: {
      jumpType, // 登录成功后： '0' 停留当前界面,  '1' 跳转拍照首页
      backType, // 点击登录页导航栏左上角返回： '0' 停留当前界面,  '1' 跳转拍照首页
      fromPage
    }
  }
  console.log('去登录', message)
  if (getUA().isAndroid) {
    window.android.callAndroid(JSON.stringify(message))
  } else {
    window.webkit.messageHandlers.XCamera.postMessage(message)
  }
}

// 跳转到个人详情页
export const toNativeUserDetailPage = ({ userId, groupId }) => {
  const message = {
    type: 'TOVIEW_LOCAL_PAGE',
    subtype: 'the_group_personal_homepage',
    content: {
      userId,
      groupId
    }
  }

  if (getUA().isAndroid) {
    window.android.callAndroid(JSON.stringify(message))
  } else {
    window.webkit.messageHandlers.XCamera.postMessage(message)
  }
}

/**
 * 获取真实时间
 */
export const fetchRealTime = () => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackname = 'XCameraGetCurrentTime'

      window[callbackname] = (data) => {
        resolve(data)
      }

      invokeJsBridge({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'get_current_time',
        content: {
          callback: callbackname
        }
      })
    } else {
      resolve(Date.now())
    }
  })
}
//拍照日历页面 - 更多功能H5 点击“了解团队”或“使用团队功能按钮”
/**
 * 客户端逻辑如下：
 * 如果用户没有登录，就让用户去登录，登录后跳转到创建/加入团队页面；
 * 如果用户已经登录，没有团队，就跳转到创建/加入团队页面；
 * 如果用户有团队，点击跳转到第一个团队的工作圈首页；
 */
export const useGroupFeature = () => {
  const message = {
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'MORE_FEATURES_USE_GROUP',
    content: {
      callback: ''
    }
  }
  invokeJsBridge(message)
}

/**
 * 是否登录
 * @returns Promise<boolean>
 */
export const getLoginStatus = () => {
  return fetchEnvAttrs().then((data) => {
    return !!data?.user?.userId
  })
}

/**
 * 新手任务卡 - 获取用户任务完成状态列表
 */
export const getUserTasksStatus = () => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackname = 'XCameraGetNewUserTaskStatus'

      window[callbackname] = (data) => {
        resolve(JSON.parse(data))
      }

      invokeJsBridge({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_GET_USER_TASK_STATUS',
        content: {
          callback: callbackname
        }
      })
    } else {
      resolve({
        takePhoto: 0,
        setAddress: 0,
        setCoordinate: 0,
        setNavigation: 0,
        setLogo: 0,
        moreFeatures: 0
      })
    }
  })
}

/**
 * 获取当前定位地址
 */
export const getCurrentLocation = () => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackname = 'XCameraGetCurrentAddress'

      window[callbackname] = (data) => {
        resolve(JSON.parse(data))
      }

      invokeJsBridge({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_GET_CURRENT_ADDRESS',
        content: {
          callback: callbackname
        }
      })
    } else {
      resolve({
        address: '成都天府软件园'
      })
    }
  })
}

/**
 * 新手任务卡 - 开启任务
 * @param {*} taskId
 */
export const beginTask = (taskId) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackname = 'XCameraNewUserTaskDone'

      window[callbackname] = (data) => {
        resolve(data)
      }

      invokeJsBridge({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_BEGIN_USER_TASK',
        content: {
          taskId,
          callback: callbackname
        }
      })
    } else {
      resolve({
        taskId
      })
    }
  })
}

/**
 * vip 类型
 */
export const vipType = {
  0: '普通包月',
  1: '普通包年',
  2: '订阅包月',
  3: '订阅包年'
}

/**
 * 复制状态码
 * >= 3.0.050
 */
export const copyDeviceCode = () => {
  invokeJsBridge({
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'COPY_DEVICE_CODE',
    content: {}
  })
}

/**
 * 获取在线客服未读消息数量
 * >= 3.0.050
 */
export const getCustomerServiceUnreadCount = () => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackFuncName = 'getXCameraCustomerServiceUnreadCount'

      window[callbackFuncName] = function (count) {
        resolve(count)
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_GET_CUSTOMER_SERVICE_UNREAD_COUNT',
        content: {
          callback: callbackFuncName
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(0)
    }
  })
}

/**
 * 在线客服未读消息数量清空
 */
export const clearCustomerServiceUnreadCount = () => {
  invokeJsBridge({
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'H5_SET_CUSTOMER_SERVICE_UNREAD_CLEAR',
    content: {}
  })
}

/**
 * 扫描识别商品条形码
 */
export const scanBarCode = async () => {
  if (isInApp) {
    return new Promise((resolve) => {
      if (isInApp) {
        const callbackFuncName = 'XCameraScanBarCode'

        window[callbackFuncName] = function (res) {
          console.log('========条形码', res)
          resolve(res)
        }

        const message = {
          type: 'JS_CALL_NATIVE_FUNC',
          subtype: 'H5_SCAN_BAR_CODE',
          content: {
            callback: callbackFuncName
          }
        }

        if (getUA().isAndroid) {
          window.android.callAndroid(JSON.stringify(message))
        } else {
          window.webkit.messageHandlers.XCamera.postMessage(message)
        }
      } else {
        resolve('test')
      }
    })
  } else {
    return '6954767415772'
  }
}

/**
 * 通用支付场景
 */
export const pay = ({ payway, goodsId, sourceDetail }) => {
  return new Promise((resolve) => {
    if (isInApp) {
      let callbackname = 'XCameraPaymentCallback'
      callbackname += `${Date.now()}_${Math.floor(Math.random() * 1000)}`

      window[callbackname] = (paySuccess) => {
        // FIXME:
        console.log('============支付结果', paySuccess)

        resolve(paySuccess === 'true')
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_PAYMENT',
        content: {
          payway,
          goodsId,
          sourceDetail,
          callback: callbackname
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(true)
    }
  })
}

/**
 * 拍照取证落地页，点击 立即拍照
 */
export const toShotEvidencePhoto = (fromPage) => {
  if (isInApp) {
    invokeJsBridge({
      type: 'TOVIEW_LOCAL_PAGE',
      subtype: 'to_shot_evidence_photo',
      content: {
        fromPage //进入落地页的入口参数
      }
    })
  }
}

/**
 * 全球化版本，获取DailySnap拼图照片
 * @returns base64[]
 */
export const globalGetDailySnapPhotos = () => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackname = 'TimestampCamera_GetDailySnapPhotos'

      window[callbackname] = (data) => {
        resolve(JSON.parse(data))
      }

      invokeJsBridge({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_GET_DAILY_SNAP_PHOTOS',
        content: {
          callback: callbackname
        }
      })
    } else {
      resolve([])
    }
  })
}

/**
 * 全球化版本，分享DailySnap图片
 * @param {dataURL} data
 * @returns
 */
export const globalShareDailySnapImage = (data, photoCount) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackname = 'TimestampCamera_ShareDailySnapImageSuccess'

      window[callbackname] = (data) => {
        resolve(data)
      }

      invokeJsBridge({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_SHARE_DAILY_SNAP_IMAGE',
        content: {
          image: data,
          photoCount,
          callback: callbackname
        }
      })
    } else {
      resolve(null)
    }
  })
}

/**
 * 全球化获取多语言翻译
 * @param {*} lang
 * @returns
 */
export const globalTranslate = (lang) => {
  return new Promise((resolve) => {
    if (isInApp && Number(appVersionCode()) >= '20000500') {
      const callbackname = 'TimestampCamera_H5Translate'

      window[callbackname] = (data) => {
        console.log('TimestampCamera_H5Translate', data)
        resolve(JSON.parse(baseDecode(data)))
      }

      invokeJsBridge({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_TRANSLATE',
        content: {
          transKeys: Object.keys(lang),
          callback: callbackname
        }
      })
    } else {
      resolve(lang)
    }
  })
}

/**
 * 全球化分享考勤
 * @param {*} params
 * @returns
 */
export const globalShareAttendance = (params) => {
  const callbackName = 'shareAttendanceCallback'

  return new Promise((resolve) => {
    if (isInApp) {
      window[callbackName] = function (res) {
        resolve(res)
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'SHARE_ATTENDANCE',
        content: {
          ...params,
          callback: 'callbackName'
        }
      }
      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve('')
    }
  })
}

// 拍照取证支付
export const toPayEvidencePhoto = ({
  warrantChainID,
  payway,
  goodId,
  fromPage
}) => {
  return new Promise((resolve, reject) => {
    if (isInApp) {
      let callbackname = 'XCameraBuyEvidencePhotoCallback'
      callbackname += `${Date.now()}_${Math.floor(Math.random() * 1000)}`
      window[callbackname] = (paySuccess) => {
        resolve(JSON.parse(paySuccess))
      }
      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'h5_pay_evidence_photo',
        content: {
          payway,
          warrantChainID,
          goodId,
          fromPage,
          callback: callbackname
        }
      }
      invokeJsBridge(message)
    } else {
      reject(false)
    }
  })
}

// 拍照取证-下载证据包
/**
 *
 * @param {*} params
 * {
 fromPage,
 photoOssUrl,
 evidenceBc,
 certImage,
 photoFilename,
 certFilename,
 evidenceCode,
 evidenceHash,
 certificateCode
 }
 * @returns
 */
export const saveEvidencePackage = (params) => {
  return new Promise((resolve, reject) => {
    if (isInApp) {
      const callbackname = 'XCameraSaveEvidencePackageCallback'
      window[callbackname] = (data) => {
        resolve(data)
      }
      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'download_evidence_package',
        content: {
          ...params,
          callback: callbackname
        }
      }
      invokeJsBridge(message)
    } else {
      resolve(null)
    }
  })
}

export const checkIsAppInstalled = (appId) => {
  return new Promise((resolve) => {
    if (isInApp) {
      let callbackname = 'XCameracheckIsAppInstalledCallback'

      window[callbackname] = (res) => {
        console.log('检测app是否安装', appId, res, typeof res)

        resolve(JSON.parse(res))
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'check_is_app_installed',
        content: {
          appId,
          callback: callbackname
        }
      }

      console.log({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'check_is_app_installed',
        content: {
          appId,
          callback: callbackname
        }
      })

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(true)
    }
  })
}
/**
 * 设置拍摄按钮
 */
export const setTakePhotoBtn = (imgUrl, isSilence = false) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackname = 'XCameraSetTakePhotoBtnCallback'

      window[callbackname] = (data) => {
        resolve(data)
      }

      invokeJsBridge({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'set_take_photo_btn',
        content: {
          imgUrl,
          isSilence,
          callback: callbackname
        }
      })
    } else {
      resolve(true)
    }
  })
}

/**
 * 团队免广告分配名额
 */
export const groupAdFreeSelectMember = ({ groupId, memberCount }) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackname = 'XCameraGroupAdFreeSelectMember'

      window[callbackname] = (data) => {
        resolve(JSON.parse(res))
      }

      invokeJsBridge({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'H5_GROUP_AD_FREE_SELECT_MEMBER',
        content: {
          groupId,
          memberCount,
          callback: callbackname
        }
      })
    } else {
      resolve(true)
    }
  })
}

/**
 * 真实保护中心，展示水印数字底纹弹窗
 * @param {*} onClose 关闭回调
 */
export const showWatermarkNumberShadingPopup = (onClose) => {
  const callbackname = 'XCameraCloseShadingPopup'
  window[callbackname] = onClose
  const toview = {
    type: 'JS_CALL_NATIVE_FUNC',
    subtype: 'show_watermark_number_shading_popup',
    content: {
      onClose: callbackname
    }
  }
  if (isInApp) {
    invokeJsBridge(toview)
  } else {
    console.log('非客户端环境: ', JSON.stringify(toview))
  }
}

/**
 * 真实保护中心，取当前使用水印的人脸识别开关，如果未选择水印，当做未开启
 * @returns Promise<boolean>
 */
export const getCurrentFaceDetectorSwitch = () => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackname = 'XCameraFaceDetectorSwitchCallback'

      window[callbackname] = (switchStatus) => {
        console.log('当前人脸识别开关状态：', switchStatus)
        resolve(JSON.parse(switchStatus))
      }

      invokeJsBridge({
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'get_current_face_detector_switch',
        content: {
          callback: callbackname
        }
      })
    } else {
      resolve(false)
    }
  })
}

/**
 * 个人照片台账表 获取今日水印相机 app 拍摄的照片
 * 接受数据需要使用 base64 解析
 * */
export const getLocalTodayCameraData = ({
  startDate,
  endDate,
  maximumCount,
  type
}) => {
  console.log('getLocalTodayCameraData', startDate, endDate)
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackName = 'XCameraLocalTodayCameraDataCallback'
      window[callbackName] = (data) => {
        resolve(data)
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'LOCAL_TODAY_CAMERA_DATA',
        content: {
          callback: callbackName,
          startDate, // 开始时间
          endDate, // 结束时间
          maximumCount, // 查询数量，默认为100
          type, // 照片类型，0：全部  1：普通照片、2：拼图、3：边拍边拼
          sourceFrom: 0 // 0 今拍，1非今拍
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        console.log('getLocalTodayCameraData ios', message)
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(true)
    }
  })
}

/**
 * 保存文档到本地
 * */

export const saveExcelToLocal = (params) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackName = 'XCameraSaveExcelToLocalCallback'
      window[callbackName] = (data) => {
        resolve(JSON.parse(data))
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'SAVE_EXCEL_TO_LOCAL',
        content: {
          callback: callbackName,
          ...params
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(true)
    }
  })
}

/**
 * 获取本地近期的图片
 *
 * 返回值：
 * @startDate: string;
 * @endDate: String
 * @date: [] // 图片列表
 * */
export const fetchRecentPhotos = (content = {}) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackName = 'XCameraFetchRecentPhotosCallback'
      window[callbackName] = (data) => {
        resolve(JSON.parse(data))
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'LOCAL_TODAY_CAMERA_DATA_RECENT',
        content: {
          ...content,
          callback: callbackName
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        console.log('fetchRecentPhotos start', message)
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(true)
    }
  })
}

/**
 * 调起系统分享
 * */
export const shareDocument = (params) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackName = 'XCameraShareDocumentCallback'
      window[callbackName] = (data) => {
        resolve(JSON.parse(data))
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        // subtype: 'share_document',
        subtype: 'SHARE_DOCUMENT',
        content: {
          callback: callbackName,
          ...params
        }
      }
      console.log('shareDocument getUA', message)
      if (getUA().isAndroid) {
        console.log('shareDocument', message)
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(true)
    }
  })
}

/**
 * 根据 唯一 id 获取图片信息
 * */

export const getImageById = ({ id, width, height, jpegCompressionQuality }) => {
  return new Promise((resolve) => {
    if (isInApp) {
      let callbackName = 'XCameraGetImageByIdCallback'
      if (isIOS()) {
        callbackName = `XCameraGetImageByIdCallback${CryptoJS.MD5(
          id
        ).toString()}${new Date().getTime()}` // IOS 不能直接拼接 ID
      } else {
        callbackName = `XCameraGetImageByIdCallback${id}${new Date().getTime()}`
      }

      window[callbackName] = (data) => {
        resolve(JSON.parse(data))
      }
      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'GET_IMAGE_BY_ID',
        content: {
          callback: callbackName,
          id,
          width,
          height,
          jpegCompressionQuality // 压缩率 0 - 1
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(true)
    }
  })
}

/*
 * 个人台张表 native 端生成 excel 文件
 * **/
export const nativeGenerateFile = (params) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'personal_photo_ledger_generExcel',
        content: {
          ...params
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(true)
    }
  })
}

/**
 * 根据图片 iD 预览
 * */
export const previewImgById = (params) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackName = 'XCameraPreviewImgCallback'
      window[callbackName] = (data) => {
        resolve(JSON.parse(data))
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        // subtype: 'preview_img_by_id',
        subtype: 'PREVIEW_IMG_BY_ID',
        content: {
          callback: callbackName,
          ...params
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(true)
    }
  })
}

/**
 * 调用选择成员半弹窗（工作台——选择成员）
 * */
export const workspaceSelectMember = (params) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackName = `XCameraSelectTeamMemberCallback${new Date().getTime()}`
      window[callbackName] = (isSuccess, errMsg, params) => {
        resolve({
          isSuccess,
          errMsg,
          params: getUA().isAndroid ? JSON.parse(params) : params
        })
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'selectTeamMember',
        content: {
          callback: callbackName,
          ...params
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(true)
    }
  })
}

/**
 * H5 调用 app 内选择照片（工作圈 —— 上传照片）
 * */
export const chooseTeamPhoto = (params) => {
  return new Promise((resolve) => {
    if (isInApp) {
      const callbackName = 'XCameraChooseTeamPhotoCallback'
      window[callbackName] = (isSuccess, errMsg, params) => {
        resolve({
          isSuccess,
          errMsg,
          params: getUA().isAndroid ? JSON.parse(params) : params
        })
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'chooseTeamPhoto',
        content: {
          callback: callbackName,
          ...params
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(true)
    }
  })
}

/**
 * 问题整改跳转 跳转导出记录页面
 * */
export const problemRectificationExport = (data) => {
  return new Promise((resolve) => {
    if (isInApp) {
      window['XCameraProblemRectificationExport'] = () => {
        resolve()
      }

      let message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: '',
        content: {
          ...data,
          callback: 'XCameraProblemRectificationExport'
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        message = {
          callback: 'XCameraProblemRectificationExport',
          schema: 'tmc://v1/h5/web',
          params: data
        }
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve()
    }
  })
}

/**
 * Android 端照片下载页面
 * 进入页面获取所选的图片id
 * */
export const getNativeSelectedImage = (params) => {
  return new Promise((resolve) => {
    if (isInApp) {
      let callbackName = 'XCameraGetNativeSelectedImage'
      window[callbackName] = (data) => {
        resolve(JSON.parse(data))
      }

      const message = {
        type: 'JS_CALL_NATIVE_FUNC',
        subtype: 'LOCAL_MUTI_SELECT_CAMERA_DATA',
        content: {
          callback: callbackName
        }
      }

      if (getUA().isAndroid) {
        window.android.callAndroid(JSON.stringify(message))
      } else {
        window.webkit.messageHandlers.XCamera.postMessage(message)
      }
    } else {
      resolve(true)
    }
  })
}
